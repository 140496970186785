import ReactGA from "react-ga4";
import * as constants from 'util/constants';

let isGoogleInitialized = false;

export const initializeGoogleAnalytics = (gaId: string) => {
  if (gaId && !isGoogleInitialized) {
    ReactGA.initialize(gaId);
    isGoogleInitialized = true;
  }
};

export const trackGoogleEvent = (
  category: string,
  action: string,
  label: string
) => {
  if (isGoogleInitialized) {
    ReactGA.event({ category, action, label });
  }
};

export const trackGooglePage = (page: string) => {
  if (isGoogleInitialized) {
    ReactGA.send({ hitType: "pageview", page: page });
  }
};

export const checkClickEvent = (evt: any) => {
  const currentTarget = evt.target.tagName.toLowerCase();
  const parentTarget = evt.target.parentElement && evt.target.parentElement.tagName.toLowerCase();
  if (
    currentTarget === 'a' ||
    parentTarget === 'a' ||
    currentTarget === 'button' ||
    parentTarget === 'button'
  ) {
    let action = evt.target.textContent || evt.target.alt;
    let label = evt.target.href || evt.target.ariaLabel;

    // Handle email because tracking is not allowing to show '@'
    if ( (action && action.indexOf('@') > -1) || (label && label.indexOf('@') > -1) ) {
      action = handleEmail(action);
      label = handleEmail(label);
    }
    trackGoogleEvent(constants.GA_CATEGORIES.CLICK, action, label);
  }
};

const handleEmail = (email: string) => {
  return email.replace('@', '(at)');
};


export const addClickListener = () => {
  document.addEventListener('click', checkClickEvent, false);
};

export const removeClickListener = () => {
  document.removeEventListener('click', checkClickEvent, false);
};
