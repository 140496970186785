import * as React from 'react';
import * as models from 'models/index';
import { Connect } from 'store/index';
import { style } from './style';
import { css } from 'aphrodite';
import * as googleHelpers from '../../util/google-helpers';
import * as constants from '../../util/constants';

class HowItWorks extends React.Component<models.store.IAppState> {
  private styles: any;

  render() {
    googleHelpers.trackGoogleEvent(
      constants.GA_CATEGORIES.PAGE_VIEW,
      constants.GA_PAGES.HOW_IT_WORKS,
      ''
    );
    const {
      copy
    } = this.props.cmsData.text.how_it_works;


    this.styles = style({
      documentStyles: this.props.stylesData.documentWidgets,
    });

    return (
      <section>
        <div className={css(this.styles.container)}>
          <h1 dangerouslySetInnerHTML={{ __html: copy.page_title }} />

          <h2 dangerouslySetInnerHTML={{ __html: copy.headline_1 }} />
          <p dangerouslySetInnerHTML={{ __html: copy.description_1 }} />
          <img src={copy.image} alt={copy.image_alt}/>

          <h2 dangerouslySetInnerHTML={{ __html: copy.headline_2 }} />
          <p dangerouslySetInnerHTML={{ __html: copy.description_2 }} />
          <div className={css(this.styles.iframeContainer)}>
            <iframe src={copy.embed_url}></iframe>
          </div>

          <h2 dangerouslySetInnerHTML={{ __html: copy.headline_3 }} />
          <p dangerouslySetInnerHTML={{ __html: copy.description_3 }} />

        </div>
      </section>
    );
  }
}

export default Connect(HowItWorks);
