import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    information_container: {
      padding: '15px',
      ...styles.confirmationStyles.general,
    },

    headline: {
      paddingBottom: '15px',
      ...styles.globalStyles.headlines,
      ...styles.confirmationStyles.headline,

      [media.tablet]: {
        fontSize: '24px',
        ...styles.globalStyles.headlines.tablet,
        ...styles.confirmationStyles.headline.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.confirmationStyles.headline.desktop,
      },
    },

    description: {
      ...styles.confirmationStyles.description,
    },

    leaderboard_button: {
      display: 'block',
      textDecoration: 'none'
    },

    email_input: {
      ...styles.confirmationStyles.emailInput,
      ':placeholder': {
        color: '#ffffff'
      }
    }
  });
