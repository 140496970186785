import * as models from 'models/index';
import { AppStateProvider } from 'store/index';
import {
  RANDOM,
  RANDOMIZED,
  ALPHA,
  ALPHABETICAL,
  SORT_BY,
  ASCENDING,
  RANDOMIZE,
} from 'util/constants';
import * as helpers from 'util/helpers';
import { SortingMethod, SortingDirection } from 'models/grid';
import { randomizeArray } from 'util/helpers';

export function toggleSort(
  this: AppStateProvider,
  sortingMethod: SortingMethod,
  sortingDirection: SortingDirection = ASCENDING
): any {
  const { gridProps, globalProps } = this.state;
  const method = sortingMethod.toLowerCase() as SortingMethod;
  const direction = sortingDirection.toLowerCase() as SortingDirection;
  const contestantOption = Object.values(SORT_BY).includes(method)
    ? method
    : null;

  switch (method) {
    case contestantOption:
      globalProps.contestants = helpers.sortArrByProp(
        globalProps.contestants,
        method,
        direction
      );
      gridProps.sortingMethod = (contestantOption ||
        SORT_BY.NAME) as SortingMethod;
      break;
    case RANDOM:
    case RANDOMIZE:
    case RANDOMIZED:
      globalProps.contestants = helpers.shuffleArr(globalProps.contestants);
      gridProps.sortingMethod = RANDOMIZED;
      break;
    case ALPHABETICAL:
    case ALPHA:
    default:
      globalProps.contestants = helpers.sortArrByProp(
        globalProps.contestants,
        SORT_BY.NAME,
        direction
      );
      gridProps.sortingMethod = ALPHABETICAL;
  }

  gridProps.sortingDirection = direction;

  return new Promise((resolve) => {
    this.setState((prevState: models.store.IAppState) => {
      return {
        ...prevState,
        gridProps,
        globalProps,
      };
    }, resolve);
  });
}

export function createContestantList(this: AppStateProvider): any {
  const contestants = randomizeArray(this.state.cmsData.data);
  const displayEliminated = helpers.checkIfTrue(
    this.state.cmsData.text.grid.settings.display_eliminated
  );
  const { globalProps } = this.state;

  globalProps.contestants = displayEliminated
    ? contestants
    : contestants.filter(
        (contestant: models.cms.ICmsOptions) =>
          !helpers.checkIfTrue(contestant.is_eliminated)
      );

  return new Promise((resolve) => {
    this.setState((prevState: models.store.IAppState) => {
      return {
        ...prevState,
        globalProps,
      };
    }, resolve);
  });
}
