import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    countdown_container: {
      padding: '25px 0',
      textAlign: 'center',
      width: '100%',
      background: '#ffffff',
    },

    timer_wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...styles.countdownStyles.clock,
    },

    description: {
      paddingBottom: '16px',
      ...styles.globalStyles.headlines,
      ...styles.countdownStyles.description,

      [media.tablet]: {
        fontSize: '28px',
        paddingBottom: '20px',
        ...styles.globalStyles.headlines.tablet,
        ...styles.countdownStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.countdownStyles.description.desktop,
      },
    },

    countdown_unit: {
      display: 'inline-flex',
      width: '56px',
      height: '56px',
      fontSize: '26px',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      ...styles.countdownStyles.countdownUnit,

      [media.desktop]: {
        fontSize: '44px'
      }
    },

    countdown_number: {
      padding: '0px',
      lineHeight: 1,
    },

    countdown_label: {
      display: 'block',
      fontSize: '14px',
      padding: '0',
      ...styles.countdownStyles.labels,
    },

    colon: {
      fontSize: '28px',
      padding: '0 10px',
      marginTop: '20px',
      transform: 'translateY(-8%)',
      ...styles.countdownStyles.colon,

      [media.desktop]: {
        padding: '0 30px'
      }
    },
  });
