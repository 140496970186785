import * as models from 'models/index';
import { AppStateProvider } from 'store/index';
import { Auth0Client } from '@auth0/auth0-spa-js';
import * as constants from 'util/constants';
import { storageFactory } from 'util/storage_helpers';
import { IUserData, IUserSession } from 'models/user';
import { copyObject, getCurrentHost } from 'util/helpers';

let _auth0Client: any = null;

const getAuth0ClientInstance = (domain: string, clientId: string) => {
  if (_auth0Client !== null) {
    return _auth0Client;
  }
  _auth0Client = new Auth0Client({
    domain: domain,
    client_id: clientId,
  });
  return _auth0Client;
};

export function authorizeUser(this: AppStateProvider): void {
  const { userData } = this.state.globalProps;
  userData.isAuthorized = true;

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      globalProps: {
        ...prevState.globalProps,
        userData,
      },
    };
  });
}

export async function loginViaAuth0(this: AppStateProvider): Promise<void> {
  try {
    const auth0Client = getAuth0ClientInstance(
      this.state.config.auth0Domain,
      this.state.config.auth0ClientId
    );
    auth0Client.loginWithRedirect({
      redirect_uri: getCurrentHost(),
      prompt: 'login',
      appState: { path: window.location.pathname + window.location.search },
    });
  } catch (e) {}
}

export async function handleAuth0LoginRedirect(
  this: AppStateProvider,
  code: string
): Promise<string> {
  const auth0Client = getAuth0ClientInstance(
    this.state.config.auth0Domain,
    this.state.config.auth0ClientId
  );
  let path = '';
  try {
    await auth0Client
      .handleRedirectCallback()
      .then(async (data: any) => {
        path = data.appState.path;
        const idToken = await auth0Client.getIdTokenClaims();
        const accessToken = await auth0Client.getTokenSilently();
        const userProfile = await auth0Client.getUser();
        const emailVerified =
          userProfile && userProfile['email_verified'] !== false ? true : false;
        if (emailVerified) {
          // Login SUCCESS
          const { userData, userSession } = copyObject(this.state.globalProps);
          userData.name = userProfile.name;
          userData.email = userProfile.email;
          userData.method = constants.AUTH_METHODS.AUTH0;
          userSession.expiresIn = constants.AUTH0_SETTINGS.ID_TOKEN_EXPIRATION;
          userSession.idTokenPayload = idToken;
          userSession.accessToken = accessToken;
          userSession.idToken = idToken.__raw;

          const localStore = storageFactory(localStorage);
          const payload = JSON.stringify({ userData, userSession });
          localStore.setItem(constants.AUTH0_LOCALSTORAGE_LABEL, payload);

          this.setState(
            (prevState: models.store.IAppState) => {
              return {
                ...prevState,
                globalProps: {
                  ...prevState.globalProps,
                  userData,
                  userSession,
                },
              };
            },
            () => {
              this.state.voteFn
                .getContestantVotes()
                .then(this.state.authFn.authorizeUser);
            }
          );
        } else {
          this.state.modalFn.openModal(constants.MODAL_TYPES.emailNotValidated);
        }
      })
      .catch((data: any) => {});
  } catch (error) {}
  return path;
}

export function setLoginInProgress(
  this: AppStateProvider,
  inProgress: Boolean
) {
  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      authProps: {
        isLoginInProgress: inProgress,
      },
    };
  });
}

export function loginViaLocalStorage(
  this: AppStateProvider,
  userData: IUserData,
  userSession: IUserSession
): void {
  this.setState(
    (prevState: models.store.IAppState) => {
      return {
        ...prevState,
        globalProps: {
          ...prevState.globalProps,
          userData,
          userSession,
        },
      };
    },
    () => {
      this.state.voteFn
        .getContestantVotes()
        .then(this.state.authFn.authorizeUser);
    }
  );
}

export function logoff(this: AppStateProvider): void {
  const localStore = storageFactory(localStorage);
  localStore.setItem(constants.AUTH0_LOCALSTORAGE_LABEL, '');
  const auth0Client = getAuth0ClientInstance(
    this.state.config.auth0Domain,
    this.state.config.auth0ClientId
  );
  auth0Client.logout({ returnTo: getCurrentHost() });
}
