// Ad Units
export const AD_UNITS = {
  LEADERBOARD: 'leaderboard',
  LEADERBOARD_BOTTOM: 'leaderboard_bottom',
  MOBILE_LEADERBOARD_BOTTOM: 'mobile_leaderboard_bottom',
  MOBILE_LEADERBOARD: 'mobile_leaderboard',
  MOBILE_RECTANGLE: 'mobile_rectangle',
  RECTANGLE: 'rectangle',
  SQUARE: 'square',
  MODAL: 'modal',
};

// App Settings
// TEST: 810050293255c547
// PROD: d17020e9820737c1
export const DEFAULT_WID = 'd17020e9820737c1';
export const VERSION_CHECK = 'bMH72Rv5ybC4B0O1gxUE';

// Auth
export const AUTH_METHODS = {
  AUTH0: 'auth0',
  EMAIL: 'email',
  FACEBOOK: 'fb',
};

// Vote API Action Types
export const ACTION_TYPES = {
  GET: 'get',
  VOTE: 'vote',
};

// Auth - Auth0
export const AUTH0_LOCALSTORAGE_LABEL = `${DEFAULT_WID}_auth0`;

// Auth Email
export const EMAIL_LOCALSTORAGE_LABEL = `${DEFAULT_WID}_Email`;
export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

// Auth Facebook
export const FACEBOOK_LOCALSTORAGE_LABEL = `${DEFAULT_WID}_Fb`;
export const FACEBOOK_AUTH_TYPES = {
  REAUTHENTICATE: 'reauthenticate',
  REAUTHORIZE: 'reauthorize',
  REREQUEST: 'rerequest',
};

// Column Maximum Values
export const COLUMNS = {
  DESKTOP: 6,
  TABLET: 4,
  MOBILE: 2,
};

// Countdown
export const SECOND = 1;
export const MINUTE = 60;
export const HOUR = 3600;
export const DAY = 86400;

// Endpoints
export const API_CMS = 'https://widgetstate-uk.votenow.tv/v1/state/';
export const API_GEO = '/api/getregion.json';
export const API_LEADERBOARD =
  'https://voteapi-uk.votenow.tv/campaigns/results?campaignId=1009813&apiKey=extremee24stats&properties=true&excludeHiddenFields=true&roundingLogic=onAir&numDecimals=4';
export const CONNECT_ENDPOINT = 'https://voteapi-uk.votenow.tv/s2/vote';
export const MOCK_VOTE_ENDPOINT = 'http://localhost:7000/vote';
export const FACEBOOK_SHARE_PATH = '/contestant';
export const TWITTER_INTENT_URL = 'https://twitter.com/intent/tweet?text=';

// Polling Rate
export const MIN_POLLING_RATE = 3000;

// Google Analytics
export const GA_PAGES = {
  GEO: 'Out of Geo',
  GRID: 'Nominee Grid',
  CLOSED: 'Closed',
  LEADERBOARD: 'Leaderboard',
  FAQ: 'FAQs',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  HOW_IT_WORKS: 'How It Works',
};

export const GA_CATEGORIES = {
  CLICK: 'Click',
  PAGE_VIEW: 'Page View',
  MODAL_STATE: 'Modal State',
};

export const GA_EVENTS = {
  LOGOUT: 'Logout',
  LOGIN: 'Login',
  EMAIL_LOGIN: 'Email Login',
  FACEBOOK_LOGIN: 'Facebook Login',
  FACEBOOK_SHARE: 'Facebook Share',
  SUBMIT_VOTE: 'Submit Vote',
  TWITTER_SHARE: 'Twitter Share',
  TWITTER_VOTE: 'Twitter Vote',
  CLOSE_COOKIES: 'Close Cookies',
  VOTE: 'Vote',
  CONFIRM: 'Confirm',
  THANKS: 'Thanks',
};

// Images
export const IMG_LOADING = '/images/loading.svg';

// Keyboard
export const KEYS = {
  DOWN: 40,
  ESCAPE: 27,
  LEFT: 37,
  RIGHT: 39,
  TAB: 9,
  UP: 38,
};

// Modal
export const MODAL_TYPES = {
  confirmation: 'confirmation',
  errorGeneric: 'errorGeneric',
  errorOverlimit: 'errorOverlimit',
  errorWindow: 'errorWindow',
  emailNotValidated: 'emailNotValidated',
  login: 'login',
  vote: 'vote',
};

// Response Codes
export const RESPONSE_CODES = {
  GENERAL_INVALID: '16',
  VALID: '20',
  OVERLIMIT: '21',
  OUT_OF_WINDOW: '40',
};

// Routing
export const URL_REGEX = /[^0-9A-Za-z$\.+!*'(),]/g;

// Sorting
export const ASCENDING = 'ascending';
export const DESCENDING = 'descending';
export const RANDOM = 'random';
export const RANDOMIZE = 'randomize';
export const RANDOMIZED = 'randomized';
export const ALPHA = 'alpha';
export const ALPHABETICAL = 'alphabetical';
export const SORT_BY = {
  ID: 'id',
  NAME: 'name',
  DESCRIPTION_1: 'description_1',
  DESCRIPTION_2: 'description_2',
  DESCRIPTION_3: 'description_3',
  HEADLINE: 'headline',
  IMAGE: 'image',
  VIDEO: 'video',
  LINK_URL: 'link_url',
  IS_ELIMINATED: 'is_eliminated',
};

// Login

export const AUTH0_SETTINGS = {
  ID_TOKEN_EXPIRATION: 36000,
  LOGIN_POPUP: {
    WIDTH: 600,
    HEIGHT: 600,
  },
};

// newsletter
export const EMAIL_KEYWORD = '{EMAIL}';
