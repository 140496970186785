import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Connect } from 'store/index';
import { style } from './style';

class Footer extends React.Component<models.store.IAppState> {
  private styles: any;

  render() {
    const { footer, global } = this.props.stylesData;

    this.styles = style({
      globalStyles: global,
      footerStyles: footer,
      settings: { ...footer.settings },
    });

    return (
      <section>
        <div className={css(this.styles.partners)}>
          <div className={css(this.styles.contact)}>
            <span className={css(this.styles.contactItem, this.styles.contactSpan)}>
              {this.props.cmsData.text.footer.contact.label}
            </span>
            <div className={css(this.styles.contactItem)}>
              <a target="_blank" href={this.props.cmsData.text.footer.contact.email1.url}>
              {this.props.cmsData.text.footer.contact.email1.label}
              </a>
            </div>
            <div className={css(this.styles.contactItem)}>
              <a target="_blank" href={this.props.cmsData.text.footer.contact.email2.url}>
                {this.props.cmsData.text.footer.contact.email2.label}
              </a>
            </div>
          </div>
          <ul className={css(this.styles.partnersLogos)}>
            <li className={css(this.styles.hideSeparators)}/>
            {this.props.cmsData.text.footer.partners.map((logo: any, index: number) => {
              if (logo.active === "true") {
                return(
                  <React.Fragment key={`partners_${index}`}>
                  <li className={css(this.styles.partnersLogo)}>
                    <a href={logo.href} target={logo.target}>
                      <img src={logo.img} />
                    </a>
                  </li>
                  <span className={css(this.styles.partersSeparator)}/>
                  </React.Fragment>
                );
              } else { return; }
            })}
            <li className={css(this.styles.footerLogo)}>
              <a href={this.props.cmsData.text.footer.footer_logo.href} target={this.props.cmsData.text.footer.footer_logo.target}>
                <img src={this.props.cmsData.text.footer.footer_logo.img} />
              </a>
            </li>
          </ul>
        </div>
        <footer className={css(this.styles.footer)} role='contentinfo'>
          <div className={css(this.styles.container)}>{this._getLinksList()}</div>
          <p>{this.props.cmsData.text.footer.copyright}</p>
        </footer>
      </section>
    );
  }

  private _getLinksList = () => {
    return Object.keys(this.props.cmsData.text.footer.links).map(
      (footerLink, i) => {
        const { copy, link, target } = this.props.cmsData.text.footer.links[footerLink];
        let linkHtml;
        if ( target === '_blank' ) {
          linkHtml = (<a className={css(this.styles.footer_link)} href={link} target={target}>{copy}</a>);
        } else {
          linkHtml = (<Link className={css(this.styles.footer_link)} to={link}>{copy}</Link>);
        }
        return (
          link && (
            <React.Fragment key={footerLink}>
              {linkHtml}
              <span className={css(this.styles.separator)}>|</span>
            </React.Fragment>
          )
        );
      }
    );
  };
}

export default Connect(Footer);
