import { css } from 'aphrodite/no-important';
import * as React from 'react';

import AdUnit from 'components/ad-unit/index';
import Closed from 'components/closed/index';
import Countdown from 'components/countdown/index';
import ErrorMessage from 'components/error-message/index';
import Footer from 'components/footer/index';
import Grid from 'components/grid/index';
import Header from 'components/header/index';
import Loading from 'components/loading/index';
import Modal from 'components/modal/index';
import Panels from 'components/panels/index';
import Share from 'components/share/index';
import Confirmation from 'components/thanks/index';
import Vote from 'components/vote/index';
import { Route, Switch } from 'react-router-dom';
import history from 'util/history';

import * as models from 'models/index';

import { Connect } from 'store/index';

import api from 'util/api';
import * as constants from 'util/constants';
import * as fbHelpers from 'util/fb_helpers';
import * as googleHelpers from 'util/google-helpers';
import {
  checkIfTrue,
  getDeviceData,
  getQueryParamByName,
  isAuth0TokenExpired,
  normalizeForUrl,
} from 'util/helpers';
import { storageFactory } from 'util/storage_helpers';

import { style } from './style';
import Leaderboard from 'components/leaderboard';
import TermsAndConditions from 'components/terms-and-conditions';
import Faqs from 'components/faqs';
import HowItWorks from 'components/how-it-works';
import { IUserData, IUserSession } from 'models/user';
import { getConfig } from 'util/config';
import CookiesModal from '../cookies-modal';

const localStore = storageFactory(localStorage);

class App extends React.Component<models.store.IAppState> {
  public timeoutCms: number;
  public timeoutStyles: number;

  public async componentDidMount(): Promise<void> {
    const config = await getConfig();
    let wid = constants.DEFAULT_WID;

    if (config) {
      this.props.globalFn.setConfig(config);
      wid = config.defaultWid;
    }

    api
      .fetchCms(wid)
      .then((cmsData: models.api.ICmsData | null) => {
        if (cmsData) {
          fbHelpers.initializeFbSdk(cmsData.social.fb.id);
          return this.props.cmsFn.storeCmsData(cmsData);
        }
        return undefined;
      })
      .then(() => {
        if (this.props.cmsData.text) {
          return api.fetchStyles(
            this.props.cmsData.text.snapshot_settings.wid.styles
          );
        }
        return undefined;
      })
      .then((stylesData: models.api.ICmsData | null) => {
        if (stylesData) {
          this.props.cmsFn.storeStyles(stylesData);
        }
      })
      .then(() => {
        if (this.props.cmsData.text) {
          const faqs = this.props.cmsData.text.snapshot_settings.wid.faqs;
          if (faqs) {
            return api.fetchDocumentWidget(faqs);
          }
        }
        return undefined;
      })
      .then((faqs: string | null) => {
        if (faqs) {
          this.props.cmsFn.storeDocumentData({ faqs: faqs });
        }
      })
      .then(() => {
        if (this.props.cmsData.text) {
          const howItWork = this.props.cmsData.text.snapshot_settings.wid
            .how_it_works;
          if (howItWork) {
            return api.fetchDocumentWidget(howItWork);
          }
        }
        return undefined;
      })
      .then((howWorks: string | null) => {
        if (howWorks) {
          this.props.cmsFn.storeDocumentData({ how_it_works: howWorks });
        }
      })
      .then(() => {
        if (this.props.cmsData.text) {
          const terms_and_conditions = this.props.cmsData.text.snapshot_settings
            .wid.terms_and_conditions;
          if (terms_and_conditions) {
            return api.fetchDocumentWidget(terms_and_conditions);
          }
        }
        return undefined;
      })
      .then((termsConditions: string | null) => {
        if (termsConditions) {
          this.props.cmsFn.storeDocumentData({
            terms_and_conditions: termsConditions,
          });
        }
      })
      .then(async () => {
        const state = getQueryParamByName('state', window.location.href);
        const code = getQueryParamByName('code', window.location.href);
        if (state && code) {
          const path = await this.props.authFn.handleAuth0LoginRedirect(code);
          history.push(path);
        }
        await this.props.authFn.setLoginInProgress(false);
      })
      .then(() => {
        if (this.props.cmsProps.isAppReady && this.props.cmsData.text) {
          googleHelpers.initializeGoogleAnalytics(
            this.props.cmsData.settings.google_analytics
          );
          googleHelpers.addClickListener();
          this._pollCms();
          this._pollStyles();
        }

        const device = getDeviceData();
        this.props.globalFn.setDevice(device);
        this._readLocalStore();
      })
      .then(() => {
        if (this.props.match.params.name) {
          this._displayContestant();
        }
      });
  }

  public componentDidUpdate(prevProps: models.store.IAppState) {
    if (prevProps.cmsData.settings && this.props.cmsData.settings) {
      const isWindowOpen = checkIfTrue(
        this.props.cmsData.settings.window_status
      );
      if (
        this.props.cmsData.settings.window_status !==
          prevProps.cmsData.settings.window_status &&
        isWindowOpen
      ) {
        this.props.gridFn.createContestantList();
      }
    }
  }

  public componentWillUnmount(): void {
    clearTimeout(this.timeoutCms);
    clearTimeout(this.timeoutStyles);
    googleHelpers.removeClickListener();
  }

  public render(): React.ReactNode {
    if (
      !this.props.cmsProps.isAppReady ||
      this.props.authProps.isLoginInProgress ||
      !this.props.cmsData.text
    ) {
      return <Loading />;
    }

    const { text } = this.props.cmsData;
    const { stylesData } = this.props;

    // Get location
    const href = new URL(window.location.href);
    const location = href.pathname.substring(1);
    let showCountdown = false;
    if (location === '' || location === 'leaderboard') {
      showCountdown = true;
    }

    const styles = style({
      pageStyles: this.props.stylesData.app,
      globalStyles: this.props.stylesData.global,
      modalOpen: this.props.modalProps.type !== '',
    });

    const modalMap = {
      confirmation: (
        <Confirmation>
          <Share />
        </Confirmation>
      ),
      errorGeneric: (
        <ErrorMessage
          data={text.errors.generic}
          styles={stylesData.errors.generic}
        />
      ),
      errorOverlimit: (
        <ErrorMessage
          data={text.errors.overlimit}
          styles={stylesData.errors.overlimit}
        />
      ),
      errorWindow: (
        <ErrorMessage
          data={text.errors.window}
          styles={stylesData.errors.window}
        />
      ),
      emailNotValidated: (
        <ErrorMessage
          data={text.errors.email_not_validated}
          styles={stylesData.errors.generic}
        />
      ),
      vote: <Vote />,
    };

    return (
      <div className={css(styles.page)}>
        <div
          className={css(styles.app_container)}
          aria-hidden={this.props.modalProps.type !== ''}
        >
          {checkIfTrue(text.ads.leaderboard.settings.display) && (
            <AdUnit size={constants.AD_UNITS.LEADERBOARD} />
          )}

          {checkIfTrue(text.ads.mobile_leaderboard.settings.display) && (
            <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD} />
          )}

          {checkIfTrue(text.header.settings.display) && (
            <Header location={this.props.location}>
              {checkIfTrue(text.ads.rectangle.settings.display) && (
                <AdUnit size={constants.AD_UNITS.RECTANGLE} />
              )}
              {checkIfTrue(text.ads.mobile_rectangle.settings.display) && (
                <AdUnit size={constants.AD_UNITS.MOBILE_RECTANGLE} />
              )}
            </Header>
          )}

          {checkIfTrue(text.closed.settings.display_countdown) &&
            showCountdown && <Countdown key='countdown' />}

          {this.renderContent()}

          {checkIfTrue(text.footer.settings.display) && <Footer />}
        </div>

        {modalMap[this.props.modalProps.type] && (
          <Modal>
            {modalMap[this.props.modalProps.type]}
            {checkIfTrue(text.ads.modal.settings.display) &&
              this.props.modalProps.type !== 'login' && (
                <AdUnit size={constants.AD_UNITS.MODAL} />
              )}
          </Modal>
        )}
      </div>
    );
  }

  renderContent = () => {
    const { text, settings } = this.props.cmsData;
    const { stylesData } = this.props;

    const styles = style({
      globalStyles: stylesData.global,
    });

    return (
      <div className={css(styles.app)} role='main'>
        <CookiesModal key='cookies-modal' />
        {/* Replace the harcoded paths by ones specified in the CMS */}
        <Switch>
          <Route exact={true} path='/faqs' component={Faqs} />
          <Route exact={true} path='/how-it-works' component={HowItWorks} />
          <Route
            exact={true}
            path='/terms-and-conditions'
            component={TermsAndConditions}
          />
          <Route exact={true} path='/leaderboard' component={Leaderboard} />
          <Route>
            <>
              {!checkIfTrue(settings.window_status) && <Closed></Closed>}

              {(checkIfTrue(settings.window_status) ||
                checkIfTrue(text.closed.settings.display_grid)) && (
                <Grid>
                  <Panels />
                </Grid>
              )}

              {checkIfTrue(text.ads.leaderboard_bottom.settings.display) && (
                <AdUnit size={constants.AD_UNITS.LEADERBOARD_BOTTOM} />
              )}

              {checkIfTrue(
                text.ads.mobile_leaderboard_bottom.settings.display
              ) && (
                <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD_BOTTOM} />
              )}
            </>
          </Route>
        </Switch>
      </div>
    );
  };

  _displayContestant = () => {
    const name = this.props.match.params.name.toLowerCase();

    let index = -1;
    this.props.globalProps.contestants.some((contestant: any, i: number) => {
      const contestantName = normalizeForUrl(
        contestant.firstname + contestant.lastname
      ).toLowerCase();
      if (name === contestantName) {
        index = i;
        return true;
      }
      return false;
    });

    if (index !== -1) {
      this.props.globalFn.setTargetIndex(index);
      this.props.modalFn.openModal(constants.MODAL_TYPES.vote);
    }
  };

  _pollCms() {
    let wid = this.props.config.defaultWid || constants.DEFAULT_WID;

    api
      .fetchCms(wid)
      .then((cmsData: models.api.ICmsData | null) => {
        if (cmsData) {
          this.props.cmsFn.storeCmsData(cmsData);
        }
      })
      .then(() => {
        let pollingRate = constants.MIN_POLLING_RATE;
        if (
          this.props.cmsData &&
          this.props.cmsData.text.snapshot_settings.polling_rate >=
            constants.MIN_POLLING_RATE
        ) {
          pollingRate = this.props.cmsData.text.snapshot_settings.polling_rate;
        }
        this.timeoutCms = window.setTimeout(
          this._pollCms.bind(this),
          pollingRate
        );
      });
  }

  _pollStyles() {
    api
      .fetchStyles(this.props.cmsData.text.snapshot_settings.wid.styles)
      .then((stylesData: models.api.ICmsData | null) => {
        if (stylesData) {
          this.props.cmsFn.storeStyles(stylesData);
        }
      })
      .then(() => {
        let pollingRate = constants.MIN_POLLING_RATE;

        if (
          this.props.cmsData &&
          this.props.stylesData.snapshotSettings.pollingRate >=
            constants.MIN_POLLING_RATE
        ) {
          pollingRate = this.props.stylesData.snapshotSettings.pollingRate;
        }
        this.timeoutStyles = window.setTimeout(
          this._pollStyles.bind(this),
          pollingRate
        );
      });
  }

  _readLocalStore = () => {
    if (localStore.getItem(constants.AUTH0_LOCALSTORAGE_LABEL)) {
      const payload = JSON.parse(
        localStore.getItem(constants.AUTH0_LOCALSTORAGE_LABEL)
      );
      const userData: IUserData = payload['userData'];
      const userSession: IUserSession = payload['userSession'];
      if (!isAuth0TokenExpired(userSession.idTokenPayload)) {
        // Auto log in if token is valid
        const email = userData.email;
        this.props.termsFn.updateTerms(email);
        this.props.authFn.loginViaLocalStorage(userData, userSession);
      } else {
        // Destroy session data if token expired
        this.props.authFn.logoff();
      }
    }
  };
}

export default Connect(App);
