import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, darkenColor, media } from '../../util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    hidden: {
      display: 'none',
    },

    container: {
      position: 'fixed',
      bottom: '0',
      left: '0',
      ...styles.cookiesStyles.container,

      [media.tablet]: {
        ...styles.cookiesStyles.container.tablet,
      },
    },

    headline: {
      ...styles.cookiesStyles.headline,
    },

    content: {
      ...styles.cookiesStyles.content,
      [children('a')]: {
        ...styles.cookiesStyles.content.link,

        ':hover': {
          color: darkenColor(styles.cookiesStyles.content.link.color),
        },

        ':focus': {
          color: darkenColor(styles.cookiesStyles.content.link.color),
        },
      },
    },

    close_container: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },

    accept: {
      ...styles.cookiesStyles.button,
    },

    line: {
      ...styles.cookiesStyles.line,
    },

    icon: {
      margin: '0',
      [children('span')]: {
        display: 'flex',
        [children('svg')]: {
          [children('g')]: {
            ...styles.cookiesStyles.icon,
          },
        },
      },
    },
  });
