import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import * as helpers from 'util/helpers';

class CountdownUnit extends React.Component<models.countdown.ICountdownUnitProps> {
  public render() {
    const styles = this.props.styles ? this.props.styles : {};
    const { displayLabel, labels } = this.props;
    const label = this.props.number === '01' ? labels.singular : labels.plural;
    const ariaHidden = this.props.ariaHidden;

    return (
      <div
        className={css(styles.countdown_unit)}
        aria-hidden={ariaHidden ? ariaHidden : false}
        role='text'
      >
        {helpers.checkIfTrue(displayLabel) && (
          <span className={css(styles.countdown_label)}> {label} </span>
        )}
        <span className={css(styles.countdown_number)}>
          {' '}
          {this.props.number}{' '}
        </span>
      </div>
    );
  }
}

export default CountdownUnit;
