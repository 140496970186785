/* tslint:disable:no-shadowed-variable */
import { css } from 'aphrodite/no-important';
import Button from 'components/button';
import * as models from 'models/index';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Connect } from 'store/index';
import { useVoteAttempt } from 'store/vote';
import * as constants from 'util/constants';
import * as helpers from 'util/helpers';
import history from 'util/history';
import { LeftArrowIcon, RightArrowIcon } from 'util/icons';
import { style } from './style';
import * as googleHelpers from '../../util/google-helpers';

type VoteProps = models.store.IAppState & models.global.IGenericProps;

function Vote(props: VoteProps) {
  if (
    helpers.isAuth0TokenExpired(props.globalProps.userSession.idTokenPayload)
  ) {
    props.authFn.logoff();
  }

  const MAX_CONTESTANT_VOTES = parseInt(
    props.cmsData.text.vote_settings.max_votes_per_contestant,
    10
  );
  const MAX_TOTAL_VOTES = parseInt(
    props.cmsData.text.vote_settings.max_votes_total,
    10
  );
  const isMultiVote = MAX_CONTESTANT_VOTES > 1;
  const defaultVoteCount = isMultiVote ? 0 : 1;
  const { voteAttempt, setVoteAttempt, isUserValid } = useVoteAttempt(
    props.globalProps.userData
  );
  const { contestantIndex } = props.globalProps;
  const contestant = props.globalProps.contestants[contestantIndex];
  const { copy, buttons } = props.cmsData.text.confirmation;
  const contestantVotes = props.voteProps.contestantVotes[contestant.id] || 0;
  const totalContestants = props.globalProps.contestants.length;
  const [voteCount, setVoteCount] = useState(contestantVotes);
  const isEliminated = helpers.checkIfTrue(contestant.is_eliminated);
  const isWindowOpen = helpers.checkIfTrue(
    props.cmsData.settings.window_status
  );
  const styles = style({
    globalStyles: props.stylesData.global,
    voteStyles: props.stylesData.confirmation,
  });

  // Set vote counts if the contestant changes
  useEffect(() => {
    const voteCount =
      props.voteProps.contestantVotes[contestant.id] || defaultVoteCount;
    setVoteCount(voteCount);
  }, [contestantIndex]);

  useEffect(() => {
    if (!voteAttempt) {
      googleHelpers.trackGoogleEvent(
        constants.GA_CATEGORIES.MODAL_STATE,
        constants.GA_EVENTS.VOTE,
        contestant['id']
      );
    } else if (isUserValid) {
      googleHelpers.trackGoogleEvent(
        constants.GA_CATEGORIES.MODAL_STATE,
        constants.GA_EVENTS.CONFIRM,
        contestant['id']
      );
    }
    const { contestantIndex } = props.globalProps;
    const contestantName = helpers.normalizeForUrl(
      props.globalProps.contestants[contestantIndex].firstname +
        props.globalProps.contestants[contestantIndex].lastname
    );
    history.push(`/${contestantName}`);

    document.addEventListener('keydown', _handleKeyDown);

    if (voteAttempt && isUserValid) {
      const race = props.cmsData.text.vote_settings.race_id;
      props.voteFn.submitVote({ v: contestant.id, race: race });
    }

    if (voteAttempt && !isUserValid) {
      props.authFn.loginViaAuth0();
    }

    return () => {
      document.removeEventListener('keydown', _handleKeyDown);
      history.push('/');
    };
  }, [voteAttempt]);

  return (
    <section
      className={css(styles.vote)}
      aria-label='Confirm your vote'
      aria-live='assertive'
    >
      <div className={css(styles.media_container)}>
        {contestant.vote_image && (
          <img src={contestant.vote_image} alt={contestant.firstname} />
        )}
      </div>

      <div className={css(styles.info_container)}>
        {contestant.nominee_headline && (
          <h1 className={css(styles.headline)}>
            {' '}
            {contestant.nominee_headline}{' '}
          </h1>
        )}

        {!contestant.nominee_headline && copy.universal_headline && (
          <p className={css(styles.headline)}> {copy.universal_headline} </p>
        )}

        {contestant.name && (
          <p className={css(styles.name)}>{contestant.name}</p>
        )}
      </div>
      <div className={css(styles.cta_container)}>
        {isWindowOpen && !isEliminated && (
          <Button
            ariaLabel={contestant.id}
            buttonData={buttons.vote}
            buttonStyles={props.stylesData.confirmation.buttons.vote}
            options={{ globalStyles: props.stylesData.global.buttons }}
            onClick={() => {
              if (isUserValid) {
                _handleSubmit();
              } else {
                props.authFn.loginViaAuth0();
              }
            }}
            isDisabled={_isDisabled()}
          />
        )}
      </div>

      {props.children}

      {totalContestants > 1 && (
        <>
          <button
            aria-label='Previous contestant'
            className={css(styles.nav_arrow, styles.nav_prev)}
            onClick={() => _scrollTargetIndex('left')}
          >
            <LeftArrowIcon />
          </button>

          <button
            aria-label='Next contestant'
            className={css(styles.nav_arrow, styles.nav_next)}
            onClick={() => _scrollTargetIndex('right')}
          >
            <RightArrowIcon />
          </button>
        </>
      )}
    </section>
  );

  function _handleSubmit() {
    // aria-disabled won't stop click events from being fired, so need to check here
    if (_isDisabled()) {
      return false;
    }

    if (!isUserValid) {
      return setVoteAttempt({ voteAttempt: true });
    }

    setVoteAttempt({ voteAttempt: false });
  }

  function _handleKeyDown(e: any) {
    switch (e.keyCode) {
      case constants.KEYS.LEFT:
        _scrollTargetIndex('left');
        break;

      case constants.KEYS.RIGHT:
        _scrollTargetIndex('right');
        break;

      default:
        break;
    }
  }

  function _scrollTargetIndex(direction: string) {
    const length = props.globalProps.contestants.length;

    let { contestantIndex } = props.globalProps;
    contestantIndex += direction === 'left' ? -1 : 1;
    contestantIndex = (contestantIndex + length) % length;

    props.globalFn.setTargetIndex(contestantIndex);
    const nextContestant = props.globalProps.contestants[contestantIndex];
    const contestantName = helpers.normalizeForUrl(
      nextContestant.firstname + nextContestant.lastname
    );
    history.push(`/${contestantName}`);
  }

  function _getTotalVotesRemaining(currentVotes = voteCount) {
    const votesAlreadySubmitted = props.voteProps.totalVotes;
    const unsubmittedVotes = contestantVotes - currentVotes;

    return MAX_TOTAL_VOTES - votesAlreadySubmitted + unsubmittedVotes;
  }

  function _isDisabled() {
    const votesRemaining = _getTotalVotesRemaining();
    return (
      voteCount >= MAX_TOTAL_VOTES ||
      contestantVotes >= MAX_CONTESTANT_VOTES ||
      votesRemaining < 0
    );
  }
}

export default Connect(Vote);
