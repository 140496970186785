import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    container: {
      margin: 'auto',
      color: '#ffffff',
      maxWidth: 650,
      width: '100%',
      padding: '0 10px',

      [children('strong')]: {
        color: styles.documentStyles.link.color,
        fontWeight: 400
      },

      [children('h1')]: {
        ...styles.documentStyles.title,

        [media.tablet]: {
          ...styles.documentStyles.title.tablet,
        },
      },

      [children('h2')]: {
        ...styles.documentStyles.headline,

        ':first-of-type': {
          marginTop: 0
        }

      },

      [children('h3')]: {
        ...styles.documentStyles.question
      },

      [children('p')]: {
        ...styles.documentStyles.body,

        [children('a')]: {
          ...styles.documentStyles.link,

          ':hover': {
            color: darkenColor(styles.documentStyles.link.color),
          },

          ':focus': {
            color: darkenColor(styles.documentStyles.link.color),
          },
        },
      },

      [children('ol')]: {
        paddingLeft: '20px',
        [children('li')]: {
          ...styles.documentStyles.listElement,
        },
      },
    },
  });
