import * as models from 'models/index';
import { AppStateProvider } from 'store/index';
import { normalizeStyles } from 'util/helpers';

export function handleGeoData(
  this: AppStateProvider,
  data: null | models.store.IRegionCheck
): void {
  const cmsProps: models.base.IGenericObject = { ...this.state.cmsProps };
  const { globalProps } = this.state;
  globalProps.userData.country = data ? data.userCountry : '';
  globalProps.userData.state = data ? data.userRegion : '';

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      cmsProps,
      globalProps,
    };
  });
}

export function storeCmsData(
  this: AppStateProvider,
  cmsData: models.api.ICmsData
): void {
  this.setState((prev: models.store.IAppState) => {
    return {
      ...prev,
      cmsData,
    };
  });
}

export function storeStyles(
  this: AppStateProvider,
  stylesData: models.api.ICmsData
): void {
  const cmsProps: models.base.IGenericObject = { ...this.state.cmsProps };
  const normalizedStyles = normalizeStyles({ ...stylesData.text });
  cmsProps.isAppReady = true;

  this.setState((prev: models.store.IAppState) => {
    return {
      ...prev,
      stylesData: normalizedStyles,
      cmsProps,
    };
  });
}

export function storeLeaderboardData(
  this: AppStateProvider,
  leaderboardData: any[]
): void {
  const drivers = this.state.cmsData.data;

  // Merge data drivers with leaderboard data
  let mergedDrivers = [];
  for (let i = 0; i < drivers.length; i++) {
    drivers[i]['percent'] = drivers[i]['percent'] ? drivers[i]['percent'] : 0;
    mergedDrivers.push({
      ...drivers[i],
      ...leaderboardData.find(
        (driver: any) => Number(driver.id) === Number(drivers[i].leaderboard_id)
      ),
    });
  }

  // Sort merged data using percent value
  mergedDrivers = mergedDrivers.sort((obj1, obj2) => {

    if (Number(obj1['percent']) < Number(obj2['percent'])) {
      return 1;
    }

    if (Number(obj1['percent']) > Number(obj2['percent'])) {
      return -1;
    }

    return 0;
  });
  leaderboardData = mergedDrivers;

  this.setState((prev: models.store.IAppState) => {
    return {
      ...prev,
      leaderboardData,
    };
  });
}

export function storeDocumentData(
  this: AppStateProvider,
  documentData: models.api.IDocumentData
): void {
  this.setState((prev: models.store.IAppState) => {
    return {
      documentData: {
        ...prev.documentData,
        ...documentData,
      },
    };
  });
}
