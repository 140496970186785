import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as helpers from 'util/helpers';
import { appMaxWidth, children, darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    footer: {
      backgroundColor: '#aaa',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      margin: '0 auto',
      maxWidth: helpers.checkIfTrue(styles.settings.displayBleed)
        ? 'none'
        : appMaxWidth,
      width: '100%',
      minHeight: '40px',
      ...styles.footerStyles.general,
      backgroundImage: styles.footerStyles.backgroundImage ? `url(${styles.footerStyles.backgroundImage})` : ``,

      [media.tablet]: {
        minHeight: '60px',
        ...styles.footerStyles.general.tablet,
      },
    },

    contact: {
      display: "flex",
      flexDirection: "column",
      padding: "0 10px 10px",

      [media.tablet]: {
        padding: "0 54px 10px",
      }
    },

    contactItem: {
      fontSize: "17px",
      color: "#fff",
      textDecoration: "underline",
      fontFamily: "Bariol, sans-serif !important",
      marginBottom: "5px",

      [children('a')]: {
        color: "#fff",
        margin: 0
      }
    },

    contactSpan: {
      fontWeight: 600,
      fontSize: "18px",
      textDecoration: "initial"
    },

    hideSeparators: {
      [media.tablet]: {
        position: "absolute",
        right: 54,
        height: "100%",
        background: "#000",
        width: 2
      }
    },

    partnersLogos: {
      position: "relative",
      display: "flex",
      margin: "0 0 35px",
      flexWrap: "wrap",
      alignItems: "center",

      [media.tablet]: {
        padding: "0 54px",
        justifyContent: "space-between"
      },

      [media.desktop]: {
        padding: "0 30px",
        flexWrap: "nowrap"
      }
    },

    partersSeparator: {
      width: "1px",
      height: "22px",
      background: "#fff",

      ':nth-of-type(2n)': {
        display: "none"
      },


      [media.mobileLg]: {
        ':nth-of-type(2n)': {
          display: "block"
        },

        ':nth-of-type(3n)': {
          display: "none"
        }
      },

      [media.tablet]: {
        ':nth-of-type(3n)': {
          display: "block"
        }
      },

      ':last-of-type': {
        display: "none"
      },
    },

    partnersLogo: {
      padding: "0 15px",
      margin: "15px 0",
      width: "48%",
      maxWidth: "190px",
      height: "22px",
      display: "flex",
      alignItems: "center",

      [media.mobileLg]: {
        width: "33%",
        maxWidth: "230px",
        justifyContent: "center"
      },

      [media.tablet]: {
        width: "auto"
      },

      [media.desktop]: {
        width: "200px"
      },

      [children('a')]: {
        margin: 0,
        height: "32px",
        display: "flex",
        alignItems: "center"
      }
    },

    footerLogo: {
      position: "absolute",
      right: 14,
      top: "-75px",
      zIndex: 1,

      [children('a')]: {
        margin: 0
      },

      [children('img')]: {
        width: 44
      },

      [media.desktop]: {
        position: "initial",
        [children('img')]: {
          width: 50
        }
      }

    },

    container: {
      display: 'flex',
      alignItems: 'baseline',
      flexGrow: 1,
      flexWrap: 'wrap',
      height: '100%',
      maxWidth: appMaxWidth,
      margin: 'auto',
      width: '100%',
      padding: '10px 5px',
      ...styles.footerStyles.general,

      [media.tablet]: {
        padding: '10px 20px',
        paddingLeft: '30px',
        ...styles.footerStyles.general.tablet,
      },

      [media.desktop]: {
        paddingLeft: '12px',
        ...styles.footerStyles.general.desktop,
      },
    },

    footer_link: {
      margin: '0 10px',
      textDecoration: 'none',
      ...styles.footerStyles.link,

      [media.tablet]: {
        ...styles.footerStyles.link.tablet,
      },

      [media.desktop]: {
        ...styles.footerStyles.link.desktop,
      },

      ':hover': {
        color: darkenColor(styles.footerStyles.link.color),
      },

      ':focus': {
        color: darkenColor(styles.footerStyles.link.color),
      }
    },

    separator: {
      ...styles.footerStyles.separator,

      [media.tablet]: {
        ...styles.footerStyles.separator.tablet,
      },

      [media.desktop]: {
        ...styles.footerStyles.separator.desktop,
      },

      ':last-child': {
        display: 'none'
      }
    }
  });
