import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Connect } from 'store/index';
import { style } from './style';
import User from '../user';

class Header extends React.Component<models.store.IAppState> {
  public state = {
    openMenu: false,
  };

  render() {
    const { logo, image, images, copy, logo_menu, top_nav } = this.props.cmsData.text.header;
    const { stylesData, location } = this.props;
    const headerStyle: any = {}

    const styles = style({
      globalStyles: stylesData.global,
      headerStyles: stylesData.header,
      navStyles: stylesData.nav,
      settings: { ...stylesData.header.settings },
    });

    if (images && location && images[location.pathname]) {
      headerStyle.backgroundImage = `url(${images[location.pathname].image})`;
    }

    return (
      <header
        className={css(styles.header)}
        role='banner'
        style={headerStyle}
      >
        <input
          type='checkbox'
          className={css(styles.input)}
          onChange={() => {
            this.setState({ openMenu: !this.state.openMenu });
          }}
          checked={this.state.openMenu}
        />
        <button className={css(styles.hamburger)}>
          <span className={css(styles.menuButton)}>{copy.menu.copy}</span>
          <img
            className={css(styles.logoMenu)}
            src={logo_menu.image}
            alt={logo_menu.alt_text}
          ></img>
        </button>
        <div>
          {logo && logo.image && (
            <a
              className={css(styles.logo_mobile)}
              href={logo.href}
              target={logo.target}
            >
              <img alt={logo.alt_text} src={logo.image} />
            </a>
          )}
        </div>
        <div className={css(styles.topNav)}>
          <ul className={css(styles.topNavListUl)}>
            {top_nav.links.map( (item: any, index: number) => {
              if (item.active === "true") {
                return (
                  <li key={index} className={css(styles.topNavListLi)}>
                    <a className={css(styles.topNavListLink)} target="_blank" href={item.url}>{item.label}</a>
                  </li>
                );
              } else {
                return "";
              }
            })}
          </ul>
        </div>
        <div className={css(styles.nav)}>
          <div>
            {logo && logo.image && (
              <a
                className={css(styles.logo)}
                href={logo.href}
                target={logo.target}
              >
                <img alt={logo.alt_text} src={logo.image} />
              </a>
            )}
          </div>
          <User />
          <div>
            <Link to={copy.vote.href} className={css(styles.button)}>
              {copy.vote.copy}
            </Link>
          </div>
          <div>
            <Link to={copy.leaderboard.href} className={css(styles.button)}>
              {copy.leaderboard.copy}
            </Link>
          </div>
          <div>
            <Link to={copy.how_it_works.href} className={css(styles.button)}>
              {copy.how_it_works.copy}
            </Link>
          </div>
        </div>
        <ul
          className={css(styles.nav_mobile)}
          onClick={() => {
            this.setState({ openMenu: false });
          }}
        >
          <User />
          <li>
            <Link to={copy.vote.href} className={css(styles.button)}>
              {copy.vote.copy}
            </Link>
          </li>
          <li>
            <Link to={copy.leaderboard.href} className={css(styles.button)}>
              {copy.leaderboard.copy}
            </Link>
          </li>
          <li>
            <Link to={copy.how_it_works.href} className={css(styles.button)}>
              {copy.how_it_works.copy}
            </Link>
          </li>
        </ul>

        {image && image.image && (
          <div className={css(styles.container)}>
            <img
              className={css(styles.image)}
              src={image.image}
              alt={image.alt_text}
            />
          </div>
        )}
      </header>
    );
  }
}

export default Connect(Header);
