import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as helpers from 'util/helpers';
import { appMaxWidth, children, darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    hamburger: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      zIndex: 15,
      right: '10px',
      top: '35px',

      [media.tablet]: {
        top: '60px'
      },

      [media.desktop]: {
        display: 'none'
      }
    },

    input: {
      cursor: 'pointer',
      position: 'absolute',
      right: '8px',
      top: '40px',
      width: '96px',
      height: '26px',
      zIndex: 20,

      ':checked ~ ul': {
        transform: 'none'
      },

      [media.tablet]: {
        top: '66px'
      }
    },

    topNav: {
      background: "#fff",
      position: "absolute",
      top: "0px",
      width: "100%",
      zIndex: 11
    },

    topNavList: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px 40px",
      alignContent: "center"
    },

    topNavListUl: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "0 10px",
      height: 32,

      [media.tablet]: {
        height: 55,
        padding: "0 20px"
      },

      [media.desktop]: {
        padding: "0 50px"
      }
    },

    topNavListLi: {
      ':not(:last-child)': {
        marginRight: "15px",

        [media.tablet]: {
          marginRight: "40px",
        }
      }
    },

    topNavListLink: {
      margin: "0",
      padding: "0",
      fontSize: "13px",
      textDecoration: "initial",
      fontFamily: "Bariol, sans-serif !important",
      fontWeight: 600,
      color: "#000",

      [media.tablet]: {
        fontSize: "16px"
      }
    },

    header: {
      backgroundColor: 'transparent',
      margin: '0 auto',
      maxWidth: helpers.checkIfTrue(styles.settings.displayBleed)
        ? 'none'
        : appMaxWidth,
      minHeight: '175px',
      width: '100%',
      ...styles.headerStyles.general,

      [media.tablet]: {
        ...styles.headerStyles.general.tablet,
      },

      [media.desktop]: {
        ...styles.headerStyles.general.desktop,
      },
    },

    nav: {
      display: 'none',

      [ media.desktop ]: {

        display: 'flex',
        alignItems: 'center',

        [ children( '> div:not(.menu_container)' ) ]: {
          minHeight: '33px',
          display: 'flex',
          alignItems: 'center',
        },

        [ children( '> .menu_container' ) ]: {
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '0 5px 0 0',

          ':hover': {
            textDecoration: 'none'
          },

          ':focus': {
            textDecoration: 'none'
          },

          [ media.tablet ]: {
            display: 'none'
          }
        }
      },
    },

    nav_mobile: {
      background: '#000000',
      position: 'absolute',
      padding: '50px 10px',
      zIndex: 10,
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      textAlign: 'center',
      transformOrigin: '0% 0%',
      transform: 'translate(-100%, 0)',
      transition: 'transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0)',

      [media.tablet]: {
        padding: '80px 10px'
      },

      [media.desktop]: {
        display: 'none'
      }
    },

    button: {
      color: '#ffffff',
      fontSize: '16px',
      backgroundColor: 'transparent',
      margin: '0 15px',
      minWidth: '100px',
      textAlign: 'center',
      ...styles.navStyles.link,
      textDecoration: 'none',

      [media.tablet]: {
        ...styles.navStyles.link.tablet,
      },

      [media.desktop]: {
        ...styles.navStyles.link.desktop,
      },

      ':hover': {
        color: styles.navStyles.link
          ? darkenColor(styles.navStyles.link.color)
          : '',
      },

      ':focus': {
        color: styles.navStyles.link
          ? darkenColor(styles.navStyles.link.color)
          : '',
      },
    },

    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      margin: '0 auto',
      maxWidth: appMaxWidth,
      minHeight: '300px',
      ...styles.globalStyles.container,

      [media.tablet]: {
        ...styles.globalStyles.container.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.container.desktop,
      },
    },

    copy_wrapper: {
      marginTop: 'auto',
      minWidth: '100%',
      padding: '15px 0',
    },

    headline: {
      ...styles.globalStyles.headlines,
      ...styles.headerStyles.headline,
      paddingBottom: '0px',

      [media.tablet]: {
        fontSize: '40px',
        ...styles.globalStyles.headlines.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
      },
    },

    description: {
      ...styles.headerStyles.description,

      [media.tablet]: {
        fontSize: '20px',
        ...styles.headerStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.headerStyles.description.desktop,
      },
    },

    image: {
      margin: 'auto',
      ...styles.headerStyles.image,
    },

    logo: {
      display: 'none',
      position: "relative",
      zIndex: 12,

      [media.desktop]: {
        display: 'block',
        ...styles.headerStyles.logo.desktop,
      },
    },


    logo_mobile: {
      margin: 0,
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: 20,
      ...styles.headerStyles.logo,

      [media.tablet]: {
        ...styles.headerStyles.logo.tablet,
      },

      [media.desktop]: {
        display: 'none'
      },
    },

    menuButton: {
      color: '#ffffff',
      fontSize: '16px',
      backgroundColor: 'transparent',
      minWidth: 'auto',
      textAlign: 'right',
      padding: '0',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      ...styles.navStyles.link,

      [media.tablet]: {
        ...styles.navStyles.link.tablet,
      },
      [media.desktop]: {
        ...styles.navStyles.link.desktop,
      },
    },

    logoMenu: {
      width: 'auto',
      ...styles.headerStyles.logoMenu,
    },
  });
