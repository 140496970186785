import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    headline: {
      fontSize: '30px',
      lineHeight: '.9',
      paddingTop: '30px',
      ...styles.globalStyles.headlines,
      ...styles.leaderboardStyles.headline,

      [media.tablet]: {
        ...styles.globalStyles.headlines.tablet,
        ...styles.leaderboardStyles.headline.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.leaderboardStyles.headline.desktop,
      },
    },

    subheadline_wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '-4.5vw',

      [media.tablet]: {
        flexDirection: 'row',
        marginTop: '0',
      },
    },

    description: {
      marginBottom: '20px',
      ...styles.leaderboardStyles.description,

      [media.tablet]: {
        ...styles.leaderboardStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.leaderboardStyles.description.desktop,
      },
    },

    table: {
      ...styles.leaderboardStyles.table,
      borderCollapse: 'separate',
      borderSpacing: '0 10px',

      [media.tablet]: {
        ...styles.leaderboardStyles.table.tablet,
        borderSpacing: '0 20px',
      },
    },

    table_header: {
      ...styles.leaderboardStyles.tableHeader,
      fontSize: '9px',

      [children('th')]: {
        marginBottom: '8px',
      },

      [media.tablet]: {
        ...styles.leaderboardStyles.tableHeader,
      },
    },

    position: {
      ...styles.leaderboardStyles.tableColumn.position,

      [media.tablet]: {
        ...styles.leaderboardStyles.tableColumn.position.tablet,
      },
    },

    team: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      ...styles.leaderboardStyles.tableColumn.team,

      [media.tablet]: {
        ...styles.leaderboardStyles.tableColumn.team.tablet,
      },
    },

    driver: {
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      ...styles.leaderboardStyles.tableColumn.driver,

      [media.tablet]: {
        ...styles.leaderboardStyles.tableColumn.driver.tablet,
      },
    },

    flag: {
      ...styles.leaderboardStyles.tableColumn.flag,

      [media.tablet]: {
        ...styles.leaderboardStyles.tableColumn.flag.tablet,
      },
    },

    name: {
      display: 'flex',
      alignItems: 'center',
      [children('img')]: {
        marginRight: '5px',
      },

      ...styles.leaderboardStyles.tableColumn.name,

      [media.tablet]: {
        ...styles.leaderboardStyles.tableColumn.name.tablet,
      },
    },

    votes: {
      ...styles.leaderboardStyles.tableColumn.votes,
      [media.tablet]: {
        ...styles.leaderboardStyles.tableColumn.votes.tablet,
      },
    },
  });
