import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    username_container: {
      minWidth: '120px',
      padding: '0 10px',
      maxWidth: '280px',

      [media.tablet]: {
        maxWidth: '100%'
      },

      [media.desktop]: {
        maxWidth: '280px'
      },
    },

    username: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
      maxWidth: '100%',
      ...styles.navStyles.link,
      cursor: 'initial',

      [media.desktop]: {
        color: 'inherit',
        padding: '0',
        cursor: styles.navStyles.link.cursor
      }
    },

    user_nav: {
      display: 'none',
      [media.desktop]: {
        display: 'block'
      }
    },

    user_nav_mobile: {
      display: 'block',
      [media.desktop]: {
        display: 'none'
      }
    },

    button: {
      color: '#ffffff',
      position: 'relative',
      fontSize: '16px',
      textAlign: 'center',
      backgroundColor: 'transparent',
      padding: '10px 5px',
      minWidth: '100px',
      ...styles.navStyles.link,

      [media.tablet]: {
        ...styles.navStyles.link.tablet
      },

      [media.desktop]: {
        ...styles.navStyles.link.desktop
      },

      ':hover': {
        color: styles.navStyles.link
          ? darkenColor(styles.navStyles.link.color)
          : '',
      },

      ':focus': {
        color: styles.navStyles.link
          ? darkenColor(styles.navStyles.link.color)
          : '',
      },
    },

    user: {
      display: 'inline-block',

      [media.tablet]: {
        ...styles.globalStyles.container.tablet,
      },

      [media.desktop]: {
        marginLeft: '10px',
        ...styles.globalStyles.container.desktop,
      },
    },

    active: {
      ...styles.navStyles.accountActive,
      ':hover': {
        color: styles.navStyles.accountActive.color
          ? darkenColor(styles.navStyles.accountActive.color)
          : '',
      },

      ':focus': {
        color: styles.navStyles.accountActive
          ? darkenColor(styles.navStyles.accountActive.color)
          : '',
      },
    },

    dropdown: {
      display: 'none',
      position: 'absolute',
      left: '0',
      right: '0',
      top: '100%',
      backgroundColor: '#000000',
      [children('div')]: {
        margin: '0',
        textAlign: 'center',
        width: '100%',
      },
    },

    open: {
      display: 'block',
    },
  });
