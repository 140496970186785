import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as fbHelpers from 'util/fb_helpers';
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import { FacebookIcon, InstagramIcon, TwitterIcon } from 'util/icons';
import { style } from './style';

class Share extends React.Component<models.store.IAppState> {
  public render(): React.ReactNode {
    const { copy, settings } = this.props.cmsData.text.share;
    const styles = style({
      shareStyles: this.props.stylesData.thankYou.share,
    });

    return (
      <div className={css(styles.share)}>
        {(helpers.checkIfTrue(settings.display_twitter) ||
          helpers.checkIfTrue(settings.display_facebook)) && (
          <h3 className={css(styles.cta)}>{copy.cta}</h3>
        )}

        {helpers.checkIfTrue(settings.display_twitter) && (
          <button
            className={css(styles.social_button, styles.social_twitter)}
            onClick={() =>
              helpers.twitterShare(
                copy.tweet,
                constants.GA_EVENTS.TWITTER_SHARE
              )
            }
            aria-label='Share on Twitter'
          >
            <TwitterIcon />
          </button>
        )}

        {helpers.checkIfTrue(settings.display_instagram) && (
          <button
            className={css(styles.social_button, styles.social_twitter)}
            onClick={() =>
              helpers.twitterShare(
                copy.tweet,
                constants.GA_EVENTS.TWITTER_SHARE
              )
            }
            aria-label='Share on Instagram'
          >
            <InstagramIcon />
          </button>
        )}

        {helpers.checkIfTrue(settings.display_facebook) && (
          <button
            className={css(styles.social_button, styles.social_facebook)}
            onClick={() => this._facebookShare()}
            aria-label='Share on Facebook'
          >
            <FacebookIcon />
          </button>
        )}
      </div>
    );
  }

  _facebookShare = () => {
    const { contestantIndex } = this.props.globalProps;
    const { name } = this.props.globalProps.contestants[contestantIndex];

    const shareURL = `${window.location.href}${constants.FACEBOOK_SHARE_PATH}`;
    const params = { name };

    googleHelpers.trackGoogleEvent(
      constants.GA_CATEGORIES.CLICK,
      constants.GA_EVENTS.FACEBOOK_SHARE,
      ''
    );
    fbHelpers.share(shareURL, params);
  };
}

export default Connect(Share);
