import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    closed: {
      textAlign: 'center',
      flexGrow: '1',
      ...styles.globalStyles.container,
      padding: 0,

      [media.tablet]: {
        ...styles.globalStyles.container.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.container.desktop,
      },
    },

    headline: {
      paddingTop: '20px',
      ...styles.globalStyles.headlines,
      ...styles.closedStyles.headline,

      [media.tablet]: {
        fontSize: '45px',
        paddingBottom: '2px',
        ...styles.globalStyles.headlines.tablet,
        ...styles.closedStyles.headline.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.closedStyles.headline.desktop,
      },
    },

    how_works_link: {
      textDecoration: "none"
    },

    how_works: {
      width: "100%",
      maxWidth: "180px",
      display: "flex",
      alignItems: "center",
      position: "relative",
      margin: "20px auto 0px"
    },

    icon: {
      cursor: "pointer",
      padding: "0",
      [children('span')]: {
        display: 'flex',
        [children('svg')]: {
          [children('g')]: {
            fill: "rgb(2, 253, 156)"
          },
        },
      },
    },

    line: {
      height: "3px",
      background: "rgb(152, 145, 145)",
      width: "35px",
      position: "absolute",
      left: "9px",
      bottom: "0px",
      border: "none",
      zIndex: -1
    },

    accept: {
      margin: "0px 0px 0px 40px",
      color: "rgb(255, 255, 255)",
      fontFamily: "Chaney Regular, sans-serif",
      fontSize: "14px"
    },

    description: {
      paddingBottom: '26px',
      ...styles.closedStyles.description,

      [media.tablet]: {
        fontSize: '20px',
        paddingBottom: '19px',
        ...styles.closedStyles.description.tablet,
      },

      [media.desktop]: {
        paddingBottom: '44px',
        ...styles.closedStyles.description.desktop,
      },
    },

    video_wrapper: {
      position: 'relative',
      width: '100%',
      height: '0',
      paddingBottom: '56.25%',

      [children('iframe')]: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
      },

      [media.desktop]: {
        width: '640px',
        height: '360px',
        paddingBottom: '0',
        margin: 'auto',
      },
    },
  });
