import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { style } from './style';
import { Connect } from '../../store';
import * as googleHelpers from '../../util/google-helpers';
import * as constants from '../../util/constants';

class Grid extends React.Component<models.store.IAppState> {
  private styles: any;
  public state = {
    isHovering: false
  };

  public componentDidMount(): void {
    this.props.gridFn.createContestantList();
    googleHelpers.trackGoogleEvent(
      constants.GA_CATEGORIES.PAGE_VIEW,
      constants.GA_PAGES.GRID,
      ''
    );
  }

  public componentDidUpdate(prevProps: models.store.IAppState): void {
    if (
      JSON.stringify(prevProps.cmsData.data) !==
        JSON.stringify(this.props.cmsData.data) ||
      prevProps.cmsData.text.grid.settings.display_eliminated !==
        this.props.cmsData.text.grid.settings.display_eliminated
    ) {
      this.props.gridFn.createContestantList();
    }
  }

  public render(): React.ReactNode {
    const { copy } = this.props.cmsData.text.grid;

    this.styles = style({
      globalStyles: this.props.stylesData.global,
      gridStyles: this.props.stylesData.grid,
    });

    return (
      <section className={css(this.styles.grid)}>
        <div className={css(this.styles.text_wrapper)}>
          <h1
            className={css(this.styles.headline, this.styles.gridHeadline)}
            dangerouslySetInnerHTML={{ __html: copy.headline }}
          />

          <div className={css(this.styles.subheadline_wrapper)}>
            <h1
              className={css(this.styles.description)}
              dangerouslySetInnerHTML={{ __html: copy.description }}
            />
          </div>
        </div>
        <section className={css(this.styles.panel_window, this.state.isHovering ? this.styles.is_hovering : '')}
                 onMouseEnter={() => this.setState({ isHovering: true })}
                 onMouseLeave={() => this.setState({ isHovering: false })}>
          {this.props.children}
        </section>
        {copy.additional_info && (
          <p className={css(this.styles.additional_info)}>
            {' '}
            {copy.additional_info}{' '}
          </p>
        )}
      </section>
    );
  }
}

export default Connect(Grid);
