import { AppStateProvider } from 'store/index';

export function generatePayload(
  this: AppStateProvider,
  actionType: string,
  voteData?: any
) {
  const action_type = actionType;
  const apiKey = this.state.cmsData.settings.apiKey;
  const country = this.state.globalProps.userData.country;
  const device = this.state.globalProps.device;
  const parsedAuth0Id = this.state.globalProps.userSession.idTokenPayload.sub.split(
    '|'
  );
  const user_id = parsedAuth0Id[1];
  const method = parsedAuth0Id[0];
  const state = this.state.globalProps.userData.state;
  const optIns = Object.keys(this.state.termsProps).reduce((storage, optIn) => {
    storage[`optin_${optIn}`] = this.state.termsProps[optIn].isChecked
      ? '1'
      : '0';
    return storage;
  }, Object.create(null));

  switch (actionType) {
    case 'get':
      return {
        apiKey,
        timestamp: new Date().getTime(),
        action_type,
        ...voteData,
      };
      break;
    case 'vote':
      return {
        apiKey,
        timestamp: new Date().getTime(),
        action_type,
        method,
        user_id,
        ...voteData,
      };
      break;
    case 'vote_default':
      return {
        apiKey,
        timestamp: new Date().getTime(),
        action_type,
        country,
        device,
        user_id,
        method,
        state,
        ...optIns,
        ...voteData,
      };
      break;
    default:
      // do nothing
      return null;
  }
}
