import { css } from 'aphrodite/no-important';
import Button from 'components/button';
import * as models from 'models/index';
import * as React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Connect } from 'store/index';
import { useVoteAttempt } from 'store/vote';
import * as constants from 'util/constants';
import { checkIfTrue, insertAt } from 'util/helpers';
import { getCurrentBreakpoint } from 'util/style-helpers';
import { style } from './style';
import useWindowSize from 'hooks/use-window-size';

type PanelsProps = models.store.IAppState & models.global.IGenericProps;

function Panels(props: PanelsProps) {
  const { setVoteAttempt, isUserValid } = useVoteAttempt(
    props.globalProps.userData
  );
  const { contestants } = props.globalProps;
  const buttonsData = props.cmsData.text.nominee.buttons;
  const buttonsStyles = props.stylesData.nominee.buttons;
  const { settings } = props.cmsData.text.nominee;
  const { columns } = props.cmsData.text.grid.settings;
  const limitReached = _isLimitReached();
  const isWindowOpen = checkIfTrue(props.cmsData.settings.window_status);
  const displayConfirmation = checkIfTrue(
    props.cmsData.text.grid.settings.display_confirmation
  );
  const adSettings = props.cmsData.text.ads.square.settings;
  const styles = style({
    columns: {
      mobile:
        parseInt(columns.mobile) > constants.COLUMNS.MOBILE
          ? 2
          : columns.mobile,
      tablet:
        parseInt(columns.tablet) > constants.COLUMNS.TABLET
          ? 4
          : columns.tablet,
      desktop:
        parseInt(columns.desktop) > constants.COLUMNS.DESKTOP
          ? 6
          : columns.desktop,
    },
    globalStyles: props.stylesData.global,
    panelStyles: props.stylesData.nominee,
  });

  const adUnit = checkIfTrue(adSettings.display) ? <div></div> : null;
  const adPosition = adSettings[`position_${getCurrentBreakpoint()}`] - 1;

  useWindowSize(); // set resize listener so that ad position gets re-checked if window is resized to different breakpoint

  // create contestant panels
  const contestantPanels = contestants.map(
    (item: models.cms.ICmsOptions | any, i: number) => {
      const votes = props.voteProps.contestantVotes[item.id] || 0;
      const voteCopy = `${votes} ${votes === 1 ? 'VOTE' : 'VOTES'}`;
      const isEliminated = checkIfTrue(item.is_eliminated);
      const { vote, eliminated, closed, limit } = buttonsData;

      // Load proper button data and copy
      let buttonData = vote;
      let buttonStyles = buttonsStyles.vote;
      if (!isWindowOpen) {
        // Window not open
        buttonData = closed;
        buttonStyles = buttonsStyles.closed;
      } else if (isEliminated) {
        // Item eliminated
        buttonData = eliminated;
      } else if (limitReached) {
        // Vote limit reached
        buttonData = limit;
        buttonStyles = buttonsStyles.limit;
      }

      // Item is a contestant
      return (
        <div className={css(styles.panel_wrapper)} key={item.id}>
          <div className={css(styles.panel)}>
            <LazyLoadImage alt={item.firstname} src={item.vote_image} />

            <div className={css(styles.text_wrapper)}>
              <div className={css(styles.vote_wrapper)}>
                {checkIfTrue(settings.display_votes) && (
                  <p className={css(styles.votes)}>{voteCopy}</p>
                )}
                <Button
                  ariaLabel={item.id}
                  buttonData={buttonData}
                  buttonStyles={buttonStyles}
                  options={{
                    globalStyles: props.stylesData.global.buttons,
                  }}
                  onClick={() => _handleClick(item)}
                  isDisabled={_isButtonDisabled(item)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  );

  // Insert ad unit into list, if any
  const panels = insertAt(contestantPanels, adUnit, adPosition);

  return panels;

  function _handleClick(contestant: models.cms.ICmsOptions) {
    if (_isButtonDisabled(contestant)) {
      return;
    }

    const MAX_VOTES_PER_CONTESTANT = parseInt(
      props.cmsData.text.vote_settings.max_votes_per_contestant,
      10
    );
    const isMultiVote = MAX_VOTES_PER_CONTESTANT > 1;
    const index = props.globalProps.contestants.findIndex(
      ({ id }: { id: string }) => id === contestant.id
    );

    props.globalFn.setTargetIndex(index);

    // If the voting is 'multi-vote', then we will always need to display the vote modal,
    // so override if false
    if (displayConfirmation || isMultiVote) {
      return props.modalFn.openModal(constants.MODAL_TYPES.vote);
    }
    const race = props.cmsData.text.vote_settings.race_id;
    return isUserValid
      ? props.voteFn.submitVote({ v: contestant.id, race: race })
      : setVoteAttempt({ voteAttempt: true });
  }

  function _isLimitReached() {
    return (
      props.voteProps.totalVotes >=
      props.cmsData.text.vote_settings.max_votes_total
    );
  }

  function _isButtonDisabled(contestant: models.cms.ICmsOptions) {
    const isEliminated = checkIfTrue(contestant.is_eliminated);
    return limitReached || (isEliminated && !displayConfirmation);
  }
}

export default Connect(Panels);
