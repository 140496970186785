import * as models from 'models/index';
import { AppStateProvider } from 'store/index';
import { copyObject } from 'util/helpers';

export function closeModal(this: AppStateProvider): void {
  const { modalProps } = this.state;
  modalProps.type = '';

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      modalProps,
    };
  });
}

export function openModal(this: AppStateProvider, type: string): void {
  const { modalProps } = copyObject(this.state);
  modalProps.type = type;

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      modalProps,
    };
  });
}
