import * as React from 'react';
import * as models from 'models/index';
import { Connect } from 'store/index';
import { style } from './style';
import { css } from 'aphrodite';
import * as googleHelpers from '../../util/google-helpers';
import * as constants from '../../util/constants';

import api from 'util/api';

class Leaderboard extends React.Component<models.store.IAppState> {
  private styles: any;
  private timeout: any;

  private fetch = () => {
    return api.fetchLeaderboard().then((leaderboardData: any[] | null) => {
      if (leaderboardData) {
        this.props.cmsFn.storeLeaderboardData(leaderboardData);
      }
    });
  };

  private _getLeaderboardList = () => {
    if (this.props.leaderboardData && this.props.leaderboardData) {
      return Object.values(this.props.leaderboardData).map(
        (driverData: {}, i) => {
          const position = i + 1;
          const driverPercent = Math.round(parseFloat(driverData['percent']));
          return (
            driverData && (
              <tr className={css(this.styles.tr)} key={position}>
                <td className={css(this.styles.position)}>
                  {position < 10 ? '0' + position : position}
                </td>
                <td
                  className={css(this.styles.team)}
                  style={{ background: 'url(' + driverData['team_logo'] + ')' }}
                ></td>
                <td
                  className={css(this.styles.driver)}
                  style={{
                    background: 'url(' + driverData['leaderboard_image'] + ')',
                  }}
                ></td>
                <td className={css(this.styles.name)}>
                  <img
                    className={css(this.styles.flag)}
                    src={driverData['flag']}
                    alt=''
                  ></img>
                  <span>
                    {driverData['firstname'] + ' ' + driverData['lastname']}
                  </span>
                </td>
                <td className={css(this.styles.votes)}>
                  {driverPercent ? driverPercent : 0}%
                </td>
              </tr>
            )
          );
        }
      );
    }
    return <tr></tr>;
  };

  private pollApi(): void {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.fetch().then(() => this.pollApi());
    }, 30000);
  }

  public componentDidMount(): void {
    this.fetch().then(() => this.pollApi());

    googleHelpers.trackGoogleEvent(
      constants.GA_CATEGORIES.PAGE_VIEW,
      constants.GA_PAGES.LEADERBOARD,
      ''
    );
  }

  public componentWillUnmount(): void {
    clearInterval(this.timeout);
  }

  render() {
    const { copy } = this.props.cmsData.text.leaderboard;

    this.styles = style({
      globalStyles: this.props.stylesData.global,
      leaderboardStyles: this.props.stylesData.leaderboard,
    });

    return (
      <section className={css(this.styles.grid)}>
        <div className={css(this.styles.text_wrapper)}>
          <h1
            className={css(this.styles.headline)}
            dangerouslySetInnerHTML={{ __html: copy.headline }}
          />
          <div className={css(this.styles.subheadline_wrapper)}>
            <h1
              className={css(this.styles.description)}
              dangerouslySetInnerHTML={{ __html: copy.description }}
            />
          </div>
        </div>
        <section>
          <table className={css(this.styles.table)}>
            <thead className={css(this.styles.table_header)}>
              <tr key='header'>
                <th>POS</th>
                <th>TEAM</th>
                <th>DRIVER</th>
                <th>NAME</th>
                <th>VOTES</th>
              </tr>
            </thead>
            <tbody>{this._getLeaderboardList()}</tbody>
          </table>
        </section>
      </section>
    );
  }
}

export default Connect(Leaderboard);
