import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({

    container: {
      margin: 'auto',
      color: '#ffffff',
      maxWidth: 650,
      width: '100%',
      padding: '0 10px',

      [children('h1')]: {
        ...styles.documentStyles.title,

        [media.tablet]: {
          ...styles.documentStyles.title.tablet,
        }
      },

      [children('h2')]: {
        ...styles.documentStyles.headline,
        marginBottom: '5px',

        [media.tablet]: {
          ...styles.documentStyles.headline.tablet,
          marginBottom: '10px',
        },

        ':first-of-type': {
          marginTop: 0
        },

        [children('strong')]: {
          color: styles.documentStyles.link.color,
          fontWeight: 400
        }
      },

      [children('img')]: {
        margin: '20px 0',

        [media.tablet]: {
          margin: '20px 0px 40px',
        },

        [media.desktop]: {
          margin: '30px 0px 80px',
        },
      },

      [children('p')]: {
        ...styles.documentStyles.body,

        [children('a')]: {
          ...styles.documentStyles.link,

          ':hover': {
            color: darkenColor(styles.documentStyles.link.color),
          },

          ':focus': {
            color: darkenColor(styles.documentStyles.link.color),
          },
        },
      },
    },

    iframeContainer: {
      position: 'relative',
      paddingBottom: '56.25%',
      paddingTop: 25,
      height: 0,
      margin: '20px 0',

      [media.tablet]: {
        margin: '20px 0px 40px',
      },

      [media.desktop]: {
        margin: '30px 0px 80px',
      },

      [children('iframe')]: {
        border: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
    }
  });
