import * as models from 'models/index';
import { AppStateProvider } from 'store/index';

export function setDevice(this: AppStateProvider, device: string): void {
  const { globalProps } = this.state;
  globalProps.device = device;

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      globalProps,
    };
  });
}

export function setConfig(this: AppStateProvider, config: any) {
  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      config: config,
    };
  });
}

export function setTargetIndex(this: AppStateProvider, index: number): void {
  const { globalProps } = this.state;
  globalProps.contestantIndex = index;

  this.setState((prevState: models.store.IAppState) => {
    return {
      ...prevState,
      globalProps,
    };
  });
}
