import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    grid: {
      flexGrow: 1,
      padding: '20px 0',
      width: '100%',
      ...styles.globalStyles.container,

      [media.tablet]: {
        ...styles.globalStyles.container.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.container.desktop,
      },
    },

    text_wrapper: {
      paddingBottom: '10px',

      [media.tablet]: {
        paddingBottom: '0',
      },
    },

    headline: {
      fontSize: '30px',
      lineHeight: '.9',
      paddingTop: '30px',
      ...styles.globalStyles.headlines,
      ...styles.gridStyles.headline,

      [media.tablet]: {
        ...styles.globalStyles.headlines.tablet,
        ...styles.gridStyles.headline.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.gridStyles.headline.desktop,
      },
    },

    subheadline_wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '-4.5vw',

      [media.tablet]: {
        flexDirection: 'row',
        marginTop: '0'
      },
    },

    description: {
      marginBottom: '20px',
      ...styles.gridStyles.description,

      [media.tablet]: {
        ...styles.gridStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.gridStyles.description.desktop,
      },
    },

    separator: {
      display: 'none',
      ...styles.gridStyles.description,

      [media.tablet]: {
        display: 'block',
        fontSize: '20px',
        padding: '0 20px',

        ...styles.gridStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.gridStyles.description.desktop,
      },
    },

    votes_remaining: {
      flexGrow: 1,
      ...styles.gridStyles.description,

      [media.tablet]: {
        fontSize: '20px',

        ...styles.gridStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.gridStyles.description.desktop,
      },
    },

    additional_info: {
      fontSize: '14px',
      padding: '10px 0 0',
      ...styles.gridStyles.additionalInfo,

      [media.tablet]: {
        fontSize: '16px',
        ...styles.gridStyles.additionalInfo.tablet,
      },

      [media.desktop]: {
        ...styles.gridStyles.additionalInfo.desktop,
      },
    },

    panel_window: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      width: 'auto',
      minWidth: '100%',
      margin: '0 -15px'
    },

    is_hovering: {
      [media.desktop]: {
        [children('> div:not(:hover)')]:{
          opacity: .4,
          transition: 'opacity .5s'
        },
      }
    },
  });
