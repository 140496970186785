import * as React from 'react';
import * as models from 'models/index';
import { Connect } from 'store/index';
import { style } from './style';
import { css } from 'aphrodite';
import * as googleHelpers from '../../util/google-helpers';
import * as constants from '../../util/constants';

class Faqs extends React.Component<models.store.IAppState> {
  private styles: any;

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    googleHelpers.trackGoogleEvent(
      constants.GA_CATEGORIES.PAGE_VIEW,
      constants.GA_PAGES.FAQ,
      ''
    );

    const content = this.props.documentData.faqs;

    this.styles = style({
      documentStyles: this.props.stylesData.documentWidgets,
    });

    return (
      <section>
        <div
          className={css(this.styles.container)}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </section>
    );
  }
}

export default Connect(Faqs);
