import axios from 'axios';

const DEFAULT_CONFIG_URL = `/config/endpoints.${
  process.env.NODE_ENV === 'development' ||
  window.location.host.indexOf('localhost') === 0
    ? 'json'
    : 'php'
}`;

export const getConfig = async () => {
  const response = await axios.get(DEFAULT_CONFIG_URL);
  const config = response.data;
  return config;
};
