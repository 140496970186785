import { css } from 'aphrodite/no-important';
import axios from 'axios';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import { style } from './style';
class User extends React.Component<models.store.IAppState> {
  public state = {
    triggeredLogin: false,
    triggeredDropdown: false,
  };

  public componentDidUpdate(): void {
    if (
      this.state.triggeredLogin &&
      this.props.globalProps.userData.isAuthorized
    ) {
      this.props.modalFn.closeModal();
    }

    if (this.state.triggeredLogin && !this.props.modalProps.type) {
      this.setState({ triggeredLogin: false });
    }
  }

  public render(): React.ReactNode {
    const { copy } = this.props.cmsData.text.nav;
    const { isAuthorized, name } = this.props.globalProps.userData;
    const styles = style({
      globalStyles: this.props.stylesData.global,
      navStyles: this.props.stylesData.nav,
    });

    let content = (
      <button
        className={css(styles.button)}
        onClick={this._handleLogon}
        dangerouslySetInnerHTML={{ __html: copy.login_cta }}
      ></button>
    );

    if (isAuthorized) {
      content = (
        <div>
          <div className={css(styles.user_nav_mobile)}>
            <div className={css(styles.username_container)}>
              <span className={css(styles.username)}>{name}</span>
            </div>
            <div>
              <a className={css(styles.button)} onClick={this._handleLogoff}>
                {copy.logout_cta}
              </a>
            </div>
            <div>
              <a
                className={css(styles.button)}
                onClick={this._handleDeleteAccount}
              >
                {copy.delete_cta}
              </a>
            </div>
          </div>
          <div className={css(styles.user_nav)}
            onMouseEnter={this._triggerDropdown}
            onMouseLeave={this._hideDropdown}
          >
            <div
              className={css(
                styles.button,
                this.state.triggeredDropdown ? styles.active : ''
              )}
              onClick={this._triggerDropdown}
            >
              <div className={css(styles.username_container)}>
                <span className={css(styles.username)}>{name}</span>
              </div>

              <div
                className={css(
                  styles.dropdown,
                  this.state.triggeredDropdown ? styles.open : ''
                )}
              >
                <div>
                  <a className={css(styles.button)} onClick={this._handleLogoff}>
                    {copy.logout_cta}
                  </a>
                </div>
                <div>
                  <a
                    className={css(styles.button)}
                    onClick={this._handleDeleteAccount}
                  >
                    {copy.delete_cta}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div className={css(styles.user)}>{content}</div>;
  }

  private _triggerDropdown = () => {
    this.setState({ triggeredDropdown: !this.state.triggeredDropdown });
  };

  private _hideDropdown = () => {
    this.setState({ triggeredDropdown: false });
  };

  private _handleLogoff = () => {
    this._hideDropdown();
    return this.props.authFn.logoff();
  };

  private _handleDeleteAccount = () => {
    const auth0Api = this.props.config.auth0ManagementApi;
    const userLoginMethod = this.props.globalProps.userSession.idTokenPayload
      .sub;
    const auth0VersionId = this.props.cmsData.settings.auth0_version_id;

    axios
      .post(
        `${auth0Api.host}${auth0Api.tokenPath}`,
        {
          client_id: auth0Api.clientId,
          client_secret: auth0Api.versionId + auth0VersionId,
          audience: auth0Api.host + auth0Api.audiencePath,
          grant_type: auth0Api.tokenGrantType,
        },
        { headers: { 'content-type': 'application/json' } }
      )
      .then(async (data) => {
        try {
          const userIdData = userLoginMethod.split('|');
          const provider = userIdData[0];
          const userId = userIdData[1];
          await axios.delete(
            `${auth0Api.host}/api/v2/users/${userLoginMethod}/identities/${provider}/${userId}` +
              userLoginMethod,
            {
              headers: {
                Authorization: 'Bearer ' + data.data.access_token,
              },
            }
          );
        } catch (e) {}
        axios
          .delete(`${auth0Api.host}${auth0Api.usersPath}` + userLoginMethod, {
            headers: {
              Authorization: 'Bearer ' + data.data.access_token,
            },
          })
          .then(() => {
            this._handleLogoff();
          })
          .catch(() => {
            this.props.modalFn.openModal(constants.MODAL_TYPES.errorGeneric);
          });
      })
      .catch((e) => {
        return e;
      });
  };

  private _handleLogon = () => {
    this.props.authFn.loginViaAuth0();
  };
}

export default Connect(User);
