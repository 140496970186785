import { css } from 'aphrodite/no-important';
import Button from 'components/button';
import * as React from 'react';
import { Connect } from 'store/index';
import * as helpers from 'util/helpers';
import { style } from './style';
import { Link } from 'react-router-dom';
import * as googleHelpers from '../../util/google-helpers';
import * as constants from '../../util/constants';
import { IAppState } from 'models/store';
import { EMAIL_KEYWORD } from '../../util/constants';
class Confirmation extends React.Component<IAppState> {
  state = {
    userEmail: '',
    subscribed: false,
    error: false,
  };

  componentDidMount = () => {
    if (
      !this.isBlackListedDomainAddress(
        this.props.globalProps.userData.email || ''
      ) &&
      this.props.globalProps.userData.email &&
      this.props.globalProps.userData.email !== this.state.userEmail
    ) {
      this.setState({ userEmail: this.props.globalProps.userData.email || '' });
    }
  };

  componentDidUpdate = (previousProps: IAppState) => {
    if (
      !this.isBlackListedDomainAddress(
        this.props.globalProps.userData.email || ''
      ) &&
      this.props.globalProps.userData.email &&
      previousProps.globalProps.userData.email !==
        this.props.globalProps.userData.email &&
      this.props.globalProps.userData.email !== this.state.userEmail
    ) {
      this.setState({ userEmail: this.props.globalProps.userData.email });
    }
  };

  isBlackListedDomainAddress = (emailAddress: string) => {
    const blackList = this.props.config.backlistedEmailDomains || [];
    let match = false;
    blackList.forEach((backlistedDomain) => {
      if (emailAddress.includes(backlistedDomain)) {
        match = true;
      }
    });
    return match;
  };

  isEmailValid = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  signUp = () => {
    if (
      !this.state.subscribed &&
      !this.state.error &&
      this.state.userEmail &&
      this.state.userEmail !== ''
    ) {
      const url = this.props.config.mailChimpSignUpUrl.replace(
        EMAIL_KEYWORD,
        this.state.userEmail
      );
      const jsonp = require('jsonp');
      jsonp(url, { param: 'c' }, (err: any, data: any) => {
        if (!err) {
          this.setState({ subscribed: true, error: false });
        }
      });
    }
  };

  emailChange = (e: any) => {
    const email = e.target.value;
    const error = !this.isEmailValid(email);
    this.setState({
      userEmail: email,
      error: error,
    });
  };

  openTeammate = ( teammateId: string ) => {
    const index = this.props.globalProps.contestants.findIndex(
      ({ id }: { id: string }) => id === teammateId
    );

    this.props.globalFn.setTargetIndex(index);
    this.props.modalFn.openModal(constants.MODAL_TYPES.vote);
  };


  public render(): React.ReactNode {
    googleHelpers.trackGoogleEvent(
      constants.GA_CATEGORIES.MODAL_STATE,
      constants.GA_EVENTS.THANKS,
      ''
    );

    const MAX_TOTAL_VOTES = parseInt(
      this.props.cmsData.text.vote_settings.max_votes_total,
      10
    );

    const { contestantIndex } = this.props.globalProps;

    const contestant = this.props.globalProps.contestants[contestantIndex];
    const {
      copy,
      settings,
      buttons,
      email_input,
    } = this.props.cmsData.text.thank_you;
    const { thankYou, global } = this.props.stylesData;
    const inputErrorStyle = this.state.error ? thankYou.emailInputError : {};

    const styles = style({
      globalStyles: global,
      confirmationStyles: thankYou,
    });

    let totalVotes = 0;
    Object.values(this.props.voteProps.contestantVotes).forEach(
      (contestantVotes: number) => {
        totalVotes = totalVotes + contestantVotes;
      }
    );

    const teammateCopy = {
      copy: contestant.teammate_copy
    }

    const teammateStyles = Object.assign({}, thankYou.buttons.vote);
    teammateStyles.color = contestant.teammate_font_color ? contestant.teammate_font_color : thankYou.buttons.vote.color;
    teammateStyles.background = contestant.teammate_background ? contestant.teammate_background : thankYou.buttons.vote.background;

    return (
      <section>
        <div className={css(styles.media_container)}>
          {contestant && (
            <img src={contestant.vote_image} alt={contestant.firstname} />
          )}
        </div>

        <div
          className={css(styles.information_container)}
          aria-live='assertive'
        >
          <h1 className={css(styles.headline)}>
            {' '}
            {totalVotes < MAX_TOTAL_VOTES
              ? copy.vote_again
              : copy.out_of_votes}{' '}
          </h1>
          <p className={css(styles.description)}>{copy.newsletter}</p>

          {!this.state.subscribed ? (
            <>
              <input
                style={inputErrorStyle}
                className={css(styles.email_input)}
                onChange={(e) => {
                  this.emailChange(e);
                }}
                type='text'
                placeholder={email_input.placeholder}
                defaultValue={this.state.userEmail}
              />
              {this.state.error && false ? (
                <div style={thankYou.textError}>{email_input.email_error}</div>
              ) : null}
              <Button
                isDisabled={this.state.error}
                buttonData={buttons.out_of_votes}
                buttonStyles={thankYou.buttons.vote}
                onClick={() => {
                  this.signUp();
                }}
                options={{
                  pushDown: true,
                  globalStyles: this.props.stylesData.global.buttons,
                }}
              />
            </>
          ) : (
            <Button
              isDisabled={true}
              buttonData={buttons.thank_you}
              buttonStyles={thankYou.buttons.vote}
              onClick={() => {}}
              options={{
                pushDown: true,
                globalStyles: this.props.stylesData.global.buttons,
              }}
            />
          )}


          {helpers.checkIfTrue(settings.display_vote_again) &&
            totalVotes < MAX_TOTAL_VOTES && contestant.teammate_id &&
              <Button
                buttonData={teammateCopy}
                buttonStyles={teammateStyles}
                onClick={() => this.openTeammate(contestant.teammate_id)}
                options={{
                  pushDown: true,
                  globalStyles: this.props.stylesData.global.buttons
                }}
              />}

          {helpers.checkIfTrue(settings.display_vote_again) &&
            totalVotes < MAX_TOTAL_VOTES && (
              <Button
                buttonData={buttons.vote_again}
                buttonStyles={thankYou.buttons.vote}
                onClick={this.props.modalFn.closeModal}
                options={{
                  pushDown: true,
                  globalStyles: this.props.stylesData.global.buttons,
                }}
              />
            )}

          <Link
            to={buttons.leaderboard.href}
            onClick={this.forceUpdate}
            className={css(styles.leaderboard_button)}
          >
            <Button
              buttonData={buttons.leaderboard}
              buttonStyles={thankYou.buttons.vote}
              onClick={this.props.modalFn.closeModal}
              ariaLabel='Return from Thanks Modal'
              options={{
                pushDown: true,
                globalStyles: this.props.stylesData.global.buttons,
              }}
            />
          </Link>

          {(helpers.checkIfTrue(settings.display_share.display_facebook) ||
            helpers.checkIfTrue(settings.display_share.display_twitter)) &&
            this.props.children}
        </div>
      </section>
    );
  }
}

export default Connect(Confirmation);
