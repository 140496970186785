import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import { style } from './style';
import { CloseIcon } from '../../util/icons';
import Cookies from 'universal-cookie';
import * as googleHelpers from '../../util/google-helpers';
import * as constants from '../../util/constants';

class CookiesModal extends React.Component<models.store.IAppState> {
  private cookieName = 'xe_accepted_cookies';
  private cookies = new Cookies();
  public state = {
    hidden: false,
  };

  public render() {
    const { copy } = this.props.cmsData.text.cookies;
    const acceptedCookies = this.cookies.get(this.cookieName);
    const styles = style({
      cookiesStyles: this.props.stylesData.cookies,
    });

    return (
      <section
        className={acceptedCookies ? css(styles.hidden) : css(styles.container)}
      >
        <h2 className={css(styles.headline)}>{copy.headline}</h2>
        <div
          className={css(styles.content)}
          dangerouslySetInnerHTML={{ __html: copy.content }}
        ></div>
        <div className={css(styles.close_container)}>
          <button
            onClick={() => this._closeCookies()}
            className={css(styles.icon)}
          >
            <CloseIcon />
          </button>
          <hr className={css(styles.line)} />
          <button
            onClick={() => this._closeCookies()}
            className={css(styles.accept)}
          >
            {copy.button}
          </button>
        </div>
      </section>
    );
  }

  _closeCookies = () => {
    googleHelpers.trackGoogleEvent(
      constants.GA_CATEGORIES.CLICK,
      constants.GA_EVENTS.CLOSE_COOKIES,
      ''
    );
    this.setState({ hidden: true });
    this.cookies.set(this.cookieName, true);
  };
}

export default Connect(CookiesModal);
